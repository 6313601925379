import {mapActions, mapMutations, mapGetters} from 'vuex';

import {required, minValue, decimal} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'weights.create',
  mixins: [validationMixin],
  data() {
    return {
      payload: {
        value: null,
        translations: [],
      },
      currentTranslations: 0,
      validationErrors: {},
    }
  },
  components: {},
  validations: {
    payload: {
      value: {
        required,
        decimal,
        minValue: minValue(0.01)
      },
    }
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters({
      isWeightCreating: 'weights/isWeightCreating',
    }),
    valueErrors() {
      let error = [];
      if (!this.$v.payload.value.$dirty) {
        return error;
      }
      if (!this.$v.payload.value.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.value.decimal) {
        error.push(this.$t('validationDecimal'));
      }
      if (!this.$v.payload.value.minValue) {
        error.push(this.$t('validationMinValue',{count: 0.01}));
      }
      if (this.validationErrors.value) {
        this.validationErrors.value.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      createWeight: 'weights/WEIGHTS_REQUEST_CREATE',
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.createWeight({
          name: this.payload.value,
          value: this.payload.value,
        }).then(() => {
          this.$toasted.success(this.$t('dataUpdated'));
          this.$router.push({name: 'weights.list'}).catch(()=> {console.log()})
        }).catch(error => {
          if (error.response.status === 422) {
            this.$toasted.error(error.response.data.message);
            this.validationErrors = error.response.data.errors
          }
        });
      }
    }
  }
}
